<template>
	<div id="login-div">
		<div class="title-holder">
			<span>Fluke</span>
			{{ $t("pages.login.title") }}
			<br />
			<f-expandable-div :expanded="loginFail">
				<div class="login-error-message">
					{{ $t("pages.login.loginFail") }}
				</div>
			</f-expandable-div>
		</div>
		<v-form v-model="isValid" ref="form">
			<div class="pages.login-card">
				<v-text-field
					data-testid="input-email"
					v-model="auth.email"
					:label="$t('labels.formEmail')"
					name="email"
					:rules="[required]"
					outlined
					autocomplete="username" />
				<v-text-field
					data-testid="input-password"
					v-model="auth.passwd"
					:label="$t('labels.formPassword')"
					name="password"
					type="password"
					:rules="[required]"
					outlined
					autocomplete="current-password"
					class="mt-4" />
			</div>
		</v-form>
		<div class="btn-aligner">
			<div class="btns-aux">
				<span @click="forgot" class="forgot-link">
					{{ $t("pages.login.forgotPassword") }}
				</span>
			</div>
			<div class="btns-main">
				<v-btn
					large
					depressed
					data-testid="btn-login"
					block
					color="primary"
					@click="login">
					{{ $t("labels.btnLogin") }}
				</v-btn>
			</div>
		</div>

		<f-language-selector />
	</div>
</template>

<script>
import validatorRules from "../../helpers/validator_rules";
import FLanguageSelector from "./LanguageSelector.vue";

export default {
	components: {
		FLanguageSelector,
	},
	data: () => ({
		isValid: false,
		failPass: false,
		loginFail: false,
		auth: {
			email: "",
			passwd: "",
		},
	}),
	watch: {
		"auth.email"() {
			this.failPass = false;
		},
		"auth.passwd"() {
			this.failPass = false;
		},
	},
	computed: {
		required() {
			return validatorRules.required(this.$t("validation.required"));
		},
	},
	methods: {
		async login() {
			this.$refs.form.validate();
			if (!this.isValid) return false;

			const response = await this.$store.dispatch("auth/login", this.auth);

			if (response == false) {
				this.failPass = true;
				this.$refs.form.validate();
				this.loginFail = true;
			} else {
				if (response.reset == false) {
					this.$router.push({
						name: "home",
					});
				} else {
					this.$router.push({
						name: "login-set-password",
					});
				}
			}
		},
		forgot() {
			this.$router.push({
				name: "login-forgot",
			});
		},
	},
};
</script>

<style lang="scss">
#login-div {
	width: 480px;
	margin-bottom: 100px;
	.login-error-message {
		background-color: rgb(194, 0, 0);
		color: #fff;
		padding: 10px 20px;
		border-radius: 5px;
		margin-top: 10px;
	}

	.login-card {
		background-color: #fff;
		padding: 40px 40px 10px 40px;
		border-radius: 3px;
		margin-bottom: 30px;
	}

	.title-holder {
		padding: 0 40px;
		margin-bottom: 30px;

		span {
			display: block;
			font-weight: 700;
			font-size: 32px;
			margin-bottom: -10px;
		}
	}

	.btn-aligner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 40px;

		.btns-main {
			button {
				width: 150px;
			}
		}
		.btns-aux {
			.forgot-link {
				text-decoration: none;
				color: #a9a9a9;
				cursor: pointer;
			}
		}
	}

	.err {
		background-color: #ff5b5b;
		color: #fff;
		padding: 0 15px;
		border-radius: 5px;
		margin-bottom: 30px;
		max-height: 0;
		transition: all 0.5s;
		opacity: 0;

		&.active {
			max-height: 100px;
			padding: 10px 15px;
			opacity: 1;
		}
	}

	.forgot {
		margin-top: 35px;
		font-size: 13px;
		cursor: pointer;
	}
}
</style>
