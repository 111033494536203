<template>
  <div class="login-links__languages">
    <div @click="setLanguage('es')" :class="{ active: $i18n.locale == 'es' }">ES</div>
    <div @click="setLanguage('en')" :class="{ active: $i18n.locale == 'en' }">EN</div>
    <div @click="setLanguage('pt')" :class="{ active: $i18n.locale == 'pt' }">PT</div>
  </div>
</template>

<script>
export default {
  created() {
    if (localStorage.getItem('currentLang'))
      this.setLanguage(localStorage.getItem('currentLang'))
  },
  methods: {
    setLanguage(language) {
      let lang = language.toLowerCase()
      this.$i18n.locale = lang
      this.$vuetify.lang.current = lang
    }
  }
}
</script>

</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/variables.scss';

.login-links {
  margin: 35px 0;
  &__languages {
    text-align: center;
    margin-top: 30px;

    & > div {
      font-size: 12px;
      display: inline-block;
      padding: 5px 12px;
      margin: 0px;
      background-color: $box-bg;
      cursor: pointer;
      border-right: 1px solid $box-selected;

      &:hover {
        background-color: $box-hover;
        color: white;
      }
      &.active {
        background-color: $box-selected;
        color: $highlight;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
</style>
